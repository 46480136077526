<template>
<div></div>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$router.replace({
        name: 'report', params: { taskId: to.query.taskId, type: to.query.type }
      })
      // 跳到该路由页面后，再替换为from.path来源路径
    })
  }
}
</script>
